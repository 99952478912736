<template>
  <el-dialog
    width="786px"
    title="开通会员"
    :visible.sync="visible"
    class="vip-tip-dialog"
  >
    <div class="msg">
      名师带练课将通过视频讲解+老师示范的形式，从PTE各项基础元素出发，用最清晰易执行的指引，带你进行细致的拆分练习。带练课程持续更新，会员期间可无限查看及练习。
    </div>
    <img
      class="img"
      src="https://ptegofeifan.oss-accelerate.aliyuncs.com/go/admin_file_1634662164914992565660.png"
      alt=""
    />
    <div class="btn">
      <el-button type="primary" @click="handleGoVip">去开通</el-button>
    </div>
  </el-dialog>
</template>

<script>
export default {
  data() {
    return {
      visible: false
    }
  },
  methods: {
    open() {
      this.visible = true
    },
    handleGoVip() {
      this.$router.push({
        name: 'vip'
      })
    }
  }
}
</script>

<style scoped lang="scss">
.vip-tip-dialog {
  .msg {
    font-size: 14px;
    line-height: 18px;
    color: #1f1f40;
  }
  .img {
    margin-top: 10px;
    width: 100%;
  }
  .btn {
    text-align: center;
    margin-top: 40px;
    padding-bottom: 20px;
    ::v-deep .el-button {
      width: 160px;
      background-color: #3b86ff;
      border-color: #3b86ff;
    }
  }
}
</style>
