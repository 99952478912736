<template>
  <div class="tag" :class="[`tag-${tagType}`, { show: label > 0 }]">
    {{ label | labelText }}
  </div>
</template>

<script>
export default {
  filters: {
    labelText(val) {
      return ['', 'Speaking', 'Listening', 'Writing', 'Reading', 'Other'][val]
    }
  },
  props: {
    label: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {}
  },
  computed: {
    tagType() {
      if (this.label == 1 || this.label == 3) {
        return '1'
      } else if (this.label == 2) {
        return '2'
      } else {
        return '3'
      }
    }
  }
}
</script>

<style scoped lang="scss">
.tag {
  display: inline-block;
  padding: 2px 8px;
  border-radius: 10px;
  font-size: 10px;
  color: #006d5d;
  background-color: #aaf9e6;
  text-align: center;
  line-height: 14px;
  visibility: hidden;
  &.show {
    visibility: visible;
  }
  .tag-1 {
    color: #ff606d;
    background-color: #ffdfe2;
  }
  .tag-2 {
    color: #3b86ff;
    background-color: #d7e7ff;
  }
  .tag-3 {
    color: #f86e21;
    background-color: #fff3df;
  }
}
</style>
