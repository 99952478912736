<template>
  <div class="detail-wrapper">
    <div class="back-title">
      <span @click="handleNavAll">所有课程</span>
      <i class="iconfont icon-class-right"></i>
      <span @click="handleNavCourse">{{ info.cate_sub_name }}</span>
      <i class="iconfont icon-class-right"></i>
      <span>{{ info.name }}</span>
    </div>

    <div class="type-introduction">
      <p class="big-title">{{ info.name }}</p>
      <div class="text">{{ info.detail }}</div>
      <div class="price">
        <div class="voucher" v-if="info.is_vip != 1">
          <i class="iconfont icon-voucher"></i>
          <span class="dianquan">
            {{ info.money_ticket }}点券/￥{{ info.money }}
          </span>
        </div>
        <div class="class-amount">
          <i class="iconfont icon-class-amount"></i>
          <span>共{{ tryList.length }}讲</span>
        </div>
        <!--        <div class="time">-->
        <!--          <i class="iconfont icon-time"></i>-->
        <!--          <span>{{ (info.video_cnt * 1000) | timeHours }}</span>-->
        <!--        </div>-->
        <div class="teacher">
          <i class="iconfont icon-teacher1"></i>
          <span>老师: {{ (info.teacher_info || {}).name }}</span>
        </div>
        <!--        <div class="share">-->
        <!--          <i class="iconfont icon-share1"></i>-->
        <!--          <span>分享</span>-->
        <!--        </div>-->
      </div>

      <template v-if="info.is_vip != 1">
        <template v-if="info.buy_status == 1">
          <div class="buy">
            <i class="iconfont icon-lock"></i>
            <span>已购买</span>
          </div>
        </template>
        <template v-if="info.buy_status == 2">
          <div class="buy buy-btn" @click="handlePay">
            <i class="iconfont icon-suoding"></i>
            <span>未购买</span>
          </div>
        </template>
      </template>
      <template v-else>
        <div class="buy buy-vip" v-if="isVip">
          <i class="iconfont icon-lock"></i>
          <span>会员专享</span>
        </div>
        <div class="buy buy-vip" v-else @click="handleGoVip">
          <i class="iconfont icon-suoding"></i>
          <span>购买会员</span>
        </div>
      </template>

      <div class="class-introduction">
        <p class="title">课程介绍</p>
        <div class="text">
          <pre>{{ info.remark }}</pre>
        </div>
      </div>
    </div>

    <p class="title">开始学习</p>

    <div class="start-learn">
      <div class="left">
        <Chapter
          :isVip="isVip"
          :list="chapterList"
          :info="info"
          :isView="isView"
        />
      </div>

      <div class="right" v-if="info.is_vip != 1">
        <p class="title-2">材料下载</p>
        <ul>
          <li v-for="item of info.res_list" :key="item.id">
            <div class="wrapper">
              <span class="name">{{ item.name }}</span>
              <a class="download" download :href="item.res_url" target="_blank">
                下载
              </a>
            </div>
          </li>
        </ul>

        <p class="title-2" style="margin-top: 34px">随堂练习</p>
        <ul>
          <li v-for="item of tryList" :key="item.id">
            <div class="wrapper">
              <span class="name">{{ item.tryName }}</span>
              <i class="iconfont icon-success" v-if="item.try_status != 2"></i>
              <div class="not-completed" v-else>
                <span>未完成</span>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>

    <template v-if="info.is_vip != 1">
      <div class="line"></div>
      <p class="title">推荐学习</p>
      <Recommend />
    </template>

    <VipTip ref="vipTip" />
    <Pay ref="pay" />
  </div>
</template>

<script lang="js">
import {mapState} from 'vuex'
import Recommend from './recommend'
import Chapter from './chapter'
import Pay from '../components/pay'
import VipTip from './vipTip'
import {numToCn} from '@/utils/num'

import {apiCourseDetial, apiChapterList} from '@/api/course'

export default {
  components: {
    Recommend,
    Chapter,
    Pay,
    VipTip
  },
  props:{
    onInit: {
      type: Function,
      default: () => {}
    }
  },
  data() {
    return {
      info: {},
      chapterListAll: []
    }
  },
  created(){
    this.fetchDetail()
  },
  computed: {
    ...mapState('app', {
      userInfo: state => state.userInfo
    }),
    isView() {
      return this.info.is_vip != 1 ? this.info.buy_status == 1 : this.isVip
    },
    isVip() {
      try {
        return this.userInfo.vip.code == 1
      } catch (e) {
        return false
      }
    },
    chapterList() {
      const {chapter_base,chapter_try } = this.info
      return (chapter_base || []).concat(chapter_try || [])
    },
    tryList() {
      return this.chapterListAll.map((item, index) => {
        return {
          ...item,
          tryName: `章节${numToCn(index+1)}随堂练习（共${item.try_list.length || 0}题）`,
          try_status: (item.try_list || []).reduce((flag, m) => flag && m.try_status == 2, true) ? 2 : 1
        }
      })
    }
  },
  methods: {
    async fetchDetail() {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      const {skillId} = this.$route.query
      const {data} = await apiCourseDetial({
        course_id: skillId
      })
      this.info = {
        ...data
      }
      this.onInit(this.info)

      await this.fetchChapterList(this.info.id)

      loading.close()
    },
    async fetchChapterList(skillId) {
      const { data } = await apiChapterList({
        course_id: skillId
      })

      this.chapterListAll = data || []
    },

    handlePay() {
      this.$refs.pay.open(
        {
          name: `${this.info.cate_sub_name}: ${this.info.name}`,
          courseType: '11',
          courseIds: [this.info.id],
          money_total: this.info.money,
          money_ticket_total: this.info.money_ticket,
          rebate: 1
        }
      )
    },
    handleGoVip() {
      this.$refs.vipTip.open()
    },
    handleNavAll() {
      this.$router.push({
        name: 'courseAllList'
      })
    },
    handleNavCourse() {
      this.$router.push({
        name: 'course',
        query: {
          cateMain: this.info.cate_main,
          cateSub: this.info.cate_sub
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.detail-wrapper {
  flex: 1;
  padding-left: 46px;
  padding-top: 25px;
  background-color: #4a4a58;

  .back-title {
    font-size: 14px;
    color: #a5a5ac;
    display: flex;
    align-items: center;

    > span {
      cursor: pointer;
      &:last-child {
        color: #ffffff;
        cursor: default;
      }

      margin-right: 5px;
    }

    > i {
      margin-right: 5px;
    }
  }

  .type-introduction {
    .big-title {
      margin-top: 40px;
      margin-bottom: 14px;
      font-size: 36px;
      color: #ffffff;
    }

    .text {
      color: #ffffff;
      line-height: 30px;
      font-size: 14px;
      margin-bottom: 27px;
    }

    .price {
      display: flex;
      font-size: 14px;

      > div {
        color: #a5a5ac;
        margin-right: 30px;

        > i {
          margin-right: 7px;
        }
      }

      .voucher {
        color: #ffffff;
      }
    }

    .buy {
      width: 106px;
      height: 32px;
      background: #36c626;
      border-radius: 5px;
      font-size: 14px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 12px;
      margin-bottom: 48px;
      color: #ffffff;
      &.buy-btn {
        background-color: #3b86ff;
        cursor: pointer;
      }
      &.buy-vip {
        background-color: #e2c395;
        color: #3a3a3a;
        cursor: pointer;
      }

      > span {
        margin-left: 4px;
      }
    }

    .class-introduction {
      .title {
        font-size: 22px;
        font-weight: bold;
        color: #ffffff;
        margin-bottom: 12px;
      }

      .text {
        color: #dbdbdd;
        font-size: 14px;
        margin-bottom: 36px;
        > pre {
          white-space: pre-wrap;
          word-wrap: break-word;
        }
      }
    }
  }

  .title {
    color: #ffffff;
    font-size: 22px;
    margin-bottom: 18px;
  }

  .start-learn {
    display: flex;

    .left {
      flex: 1;
    }

    .right {
      margin-left: 75px;
      width: 260px;
      .title-2 {
        color: #ffffff;
        font-size: 14px;
        margin-bottom: 13px;
      }

      > ul {
        > li {
          color: #a5a5ac;
          margin: 6px 0;
          font-size: 14px;
          .wrapper {
            display: flex;
            .name {
              overflow: hidden;
              text-overflow: ellipsis;
              -o-text-overflow: ellipsis;
              white-space: nowrap;
              flex: 1;
              display: block;
            }
            .download {
              margin-left: 17px;
              cursor: pointer;
              text-decoration: none;
              color: #a5a5ac;
            }
            .not-completed {
              width: 45px;
              height: 18px;
              border-radius: 15px;
              text-align: center;
              line-height: 18px;
              background: #6e6e79;
              font-size: 10px;
              color: #bababb;
              margin-left: 10px;
            }
            .icon-success {
              color: #36c626;
            }
          }
        }
      }
    }
  }

  .line {
    width: 100%;
    border-bottom: 1px solid #676767;
    margin-top: 55px;
    margin-bottom: 36px;
  }
}
</style>
