<template>
  <li
    class="chapter-item"
    :class="{ free: info.buy_status != 1 && item.free_try == 1 }"
    @click="onDetail(item)"
  >
    <div class="top">
      <template v-if="info.is_vip !== 1">
        <i
          class="iconfont icon-play1"
          v-if="isView || (info.buy_status != 1 && item.free_try == 1)"
        />
        <i class="iconfont icon-suoding" v-else />
      </template>
      <template v-else>
        <i class="iconfont icon-suoding" v-if="!isVip" />
        <i class="iconfont icon-play1" v-else />
      </template>
      <span class="text">{{ item.name }}</span>
      <span class="free-btn" v-if="info.buy_status != 1 && item.free_try == 1"
        >试听</span
      >
      <span class="time" v-else>{{ (item.video_time * 1000) | timeLen }}</span>
      <div class="finish" v-if="item.learn_status == 1">
        <i class="iconfont icon-finish"></i>
      </div>
      <!--          <img class="duigou" :src="completionImg" alt="" />-->
    </div>
    <div class="progress" v-if="item.learn_rate > 0">
      <div :style="{ width: `${item.learn_rate}%` }" />
    </div>
  </li>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      default: () => ({})
    },
    info: {
      type: Object,
      default: () => ({})
    },
    onDetail: {
      type: Function,
      default: () => {}
    },
    isView: {
      type: Boolean,
      default: false
    },
    isVip: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style scoped lang="scss">
.chapter-item {
  display: flex;
  flex-direction: column;
  width: 390px;
  height: 50px;
  background-color: #81818b;
  margin-bottom: 8px;
  position: relative;
  cursor: pointer;
  position: relative;
  &.free {
    background-color: #3b86ff;
  }
  .top {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    color: #fff;

    > i {
      margin-left: 18px;
      margin-right: 10px;
    }

    .text {
      flex: 1;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .free-btn {
      width: 62px;
      height: 26px;
      border-radius: 5px;
      background-color: #fff;
      color: #3b86ff;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 12px;
    }
    .time {
      margin-right: 23px;
      margin-left: 10px;
    }

    .finish {
      position: absolute;
      right: -6px;
      top: -6px;
      &:before {
        content: '';
        position: absolute;
        left: 15%;
        top: 15%;
        width: 70%;
        height: 70%;
        border-radius: 50%;
        background-color: #fff;
        z-index: 1;
      }
      > i {
        color: #3b86ff;
        border-radius: 50%;
        position: relative;
        z-index: 2;
      }
    }
  }

  .progress {
    width: 100%;
    height: 4px;
    > div {
      background: #3b86ff;
      height: 4px;
    }
  }
}
</style>
