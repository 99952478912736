export const navCourseDetail = ($router, info) => {
  switch (Number(info.type)) {
    case 1:
      {
        $router.push({
          name: 'chapterDetailText',
          query: {
            skillId: info.id
          }
        })
      }
      break
    case 2:
      {
        $router.push({
          name: 'chapterDetail',
          query: {
            skillId: info.id,
            type: 1
          }
        })
      }
      break
    case 3:
      {
        $router.push({
          name: 'courseChapter',
          query: {
            cateId: info.cate_main,
            skillId: info.id
          }
        })
      }
      break
  }
}
